import React from 'react';

import image1 from './images/image1.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';

import css from './SectionHowItWorks.module.css';
import LinkButton from '../LinkButton/LinkButton';
import uniqueId from 'lodash/uniqueId';

const infoArr = [
  {
    title: 'Find Your Perfect Mentor',
    text:
      'You’ve browsed through our list of successful entrepreneurs and found your dream mentor. Pick the session you want—hourly or as a package. Apply and request to book! If you’re still deciding, take your time to find the perfect match',
    imageSrc: image1,
    link: "Need help? Book a free pairing call to get started!"
  },
  {
    title: 'Begin Your Journey',
    text:
      'Your mentor has accepted your booking and is ready to work with you. They’ll provide next steps, whether that’s setting up Zoom sessions or sharing details for live meetings. Now, it’s time to hustle and put in the work.',
    imageSrc: image2,
  },
  {
    title: 'Celebrate Your Success',
    text:
      'You’ve put in the effort and seen the results. Congrats! Your mentoring sessions were a success, and your side hustle or business is thriving. Spread the word about your experience and leave a review for your mentor!',
    imageSrc: image3,
  },
];

const SectionHowItWorksNew = () => {
  
  return (
    <div className={css.rootContainer}>
      <div className={css.titleContainer}>
        <p className={css.title}>How MentorME works</p>
        <p className={css.subtitle}>
          Ready to start your side hustle? MentorME's process is designed to match you with the best
          mentors quickly and efficiently. Here’s a look at how it works:
        </p>
      </div>
      <div className={css.bodyContainer}>
        <div className={css.listContainer}>
          {infoArr.map(elem => {
            return <ListedFeature key={uniqueId("HowItWorks")} title={elem.title} text={elem.text} imageSrc={elem.imageSrc} link={elem.link} />;
          })}
        </div>
        <img className={css.imageBig} src="" />
      </div>
      <LinkButton text="Find your mentor" link="categories"/>
    </div>
  );
};

const ListedFeature = ({ title, text, imageSrc, link }) => {
  return (
    <div className={css.entryContainer}>
      <div className={css.listImageContainer}>
        <img className={css.listImage} src={imageSrc} />
      </div>
      <p className={css.listTitle}>{title}</p>
      <p className={css.listText}>{text} {link? <a>{link}</a> : null}</p>
    </div>
  );
};

export default SectionHowItWorksNew;
