import React, { useState } from 'react';

import image1 from './images/image.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';
import image4 from './images/image4.png';

import ListingCard from '../../components/ListingCard/ListingCard';
import NamedLink from '../../components/NamedLink/NamedLink';

import Marquee from "react-fast-marquee";

import css from './SectionMeetMentor.module.css';

const categoryArr = [
  {
    key: 'e_commerce',
    name: 'E-Commerce',
    image: image1,
    categoryDesc: 'Expert advice on running an online e-commerce business',
    link: './categories/e_commerce',
  },
  {
    key: 'trading',
    name: 'Trading & Investing',
    image: image2,
    categoryDesc:
      'Learn the fundamentals and psychological aspects of trading financial instruments',
    link: './categories/trading',
  },
  {
    key: 'property',
    name: 'Property',
    image: image3,
    categoryDesc: 'Learn how to make property work for you and build long-term wealth',
    link: './categories/property',
  },
  {
    key: 'business_coaching',
    name: 'Entrepreneurship',
    image: image4,
    categoryDesc: 'Expert advice to scale or start your business',
    link: './categories/business_coaching',
  },
];

const SectionMeetMentor = props => {
  const {
    newestListings
  } = props;

  if (!newestListings?.length) return null;

  const [selectedId, setSelectedId] = useState(0);
  const selectedCategory = categoryArr[selectedId].key;

  const listings = newestListings.filter(l => l.attributes.publicData?.category === selectedCategory);

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.textContainer}>
          <p className={css.title}>Meet your New Mentor</p>
          <p className={css.subtitle}>
            Learn from those who have done it before. Our mentors are successful entrepreneurs ready
            to share their journey through live mentoring.
          </p>
        </div>
        <div className={css.buttonContainer}>
          {categoryArr.map((elem, id) => (
            <div
              key={elem.key}
              onClick={() => {
                setSelectedId(id);
              }}
              className={id === selectedId ? css.selectedButton : css.selectionButton}
            >
              <img src={elem.image} className={css.buttonIcon} />
              <p className={css.buttonText}>{elem.name}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={css.recomPapersContainer}>
        <div className={css.recommendationsContainer}>
          <div className={css.recomTop}>
            <p className={css.recomTitle}>{categoryArr[selectedId].categoryDesc}</p>
            <NamedLink
              name="CategoryPage"
              params={{ category: selectedCategory }}
              className={css.seeAll}
            >
              See all {'>'}
            </NamedLink>
          </div>
        </div>
        <Marquee
          pauseOnHover={true}
          autoFill={true}
        >
          {listings.map(l => (
            <ListingCard
              className={css.recomPapers}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={() => { }}
            />
          ))}
        </Marquee>

        {/* <div className={css.recomPapersWrapper}>
          {listings?.slice(0,4)?.map(l => (
            <ListingCard
              className={css.recomPapers}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={() => { }}
            />
          ))}
        </div> */}
        <div className={css.overlay} />
      </div>

      <NamedLink
        name="CategoryPage"
        params={{ category: selectedCategory }}
        className={css.seeAllButton}
      >
        Explore mentors
      </NamedLink>
    </div>
  );
};

export default SectionMeetMentor;
