import React from 'react';

import image1 from './images/image1.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';
import imageLarge from './images/imageLarge.png';

import css from './SectionGuaranteeingQuality.module.css';
import LinkButton from '../LinkButton/LinkButton';
import uniqueId from 'lodash/uniqueId';


const infoArr = [
  {
    title: 'Initial Screening',
    text:
      'The process begins with a comprehensive questionnaire that candidates fill out when they sign up, helping us understand their business and experience.',
    imageSrc: image1,
  },
  {
    title: 'Deep Dive Interview',
    text:
      'Next, we conduct a thorough interview to delve deeper into their experience and mentoring programs, ensuring they meet our high standards.',
    imageSrc: image2,
  },
  {
    title: 'Authenticity Check',
    text:
      'Finally, we carry out a KYC process and onboarding, including verifying their ID to ensure they are who they say they are.',
    imageSrc: image3,
  },
];

const SectionGuaranteeingQuality = () => {
  return (
    <div className={css.rootContainer}>
      <div className={css.titleContainer}>
        <p className={css.title}>Guaranteeing Quality Mentorship</p>
        <p className={css.subtitle}>
          We take mentorship seriously. Our vetting process makes sure that every mentor is not only
          experienced but also genuinely interested in helping you succeed. Here’s how we ensure you
          get the best mentors:
        </p>
      </div>
      <div className={css.bodyContainer}>
        <div className={css.listContainer}>
          {infoArr.map(elem => {
            return <ListedFeature key={uniqueId("GuaranteeQuality")} title={elem.title} text={elem.text} imageSrc={elem.imageSrc} />;
          })}
        </div>
        <img className={css.imageBig} src={imageLarge} />
      </div>
      <LinkButton text="Find your mentor" link="categories"/>
    </div>
  );
};

const ListedFeature = ({ title, text, imageSrc }) => {
  return (
    <div className={css.entryContainer}>
      <div className={css.listImageContainer}>
        <img className={css.listImage} src={imageSrc} />
      </div>
      <div className={css.listTextContainer}>
        <p className={css.listTitle}>{title}</p>
        <p className={css.listText}>{text}</p>
      </div>
    </div>
  );
};

export default SectionGuaranteeingQuality;
