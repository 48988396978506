import React from 'react';

import css from './SectionFaq.module.css';
import Accordeon from '../Accordeon/Accordeon';
import LinkButton from '../LinkButton/LinkButton';

const dataArr = [
  {
    label: 'How does MentorME select its mentors?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'What types of mentoring sessions can I get?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'Can I choose my mentor on MentorME?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'How do I get started with a mentor on MentorME?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'How is MentorME different from other mentoring options?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'What types of side hustles can I start with MentorME?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'Is MentorME suitable for beginners?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
  {
    label: 'How can I join the MentorME community?',
    content: (
      <p className={css.text}>
        MentorME has a rigorous vetting process to select its mentors. We start with a detailed
        questionnaire, followed by an in-depth interview, and finally, a KYC and onboarding process
        to verify their identity and expertise. This ensures you receive guidance from only the best
        and most experienced entrepreneurs & mentors.
      </p>
    ),
  },
];

const SectionFaq = () => {
  return (
    <div className={css.root}>
      <p className={css.title}>Frequently Asked Questions</p>
      <div className={css.accordeonContaner}>
        <Accordeon radioName={'FaqRadio'} dataArr={dataArr} />
      </div>
      <LinkButton text="Find your mentor" link="categories"/>
    </div>
  );
};

export default SectionFaq;
